// File: HomeFuctuart.js
// Highway
import Highway from "@dogstudio/highway";

// GSAP
import { gsap, Power4 } from "gsap";


const smallDesktopQuerie = window.matchMedia("(max-width: 1280px)");
let overlayEl = document.getElementById("overlay-transition");
let overlayEl2 = document.getElementById("overlay-transition-2");

// HomeFluctuart
class HomeFluctuart extends Highway.Transition {
  in({ from, to, done }) {
    console.log("IN", from, to);
    // Reset Scroll
    window.scrollTo(0, 0);

    // Remove Old View
    from.remove();

    // Animation
    let sidebarText = document.getElementsByClassName("revealLeft");
    let sidebarRight = document.getElementById("right_sidebar");
    let imageRight = document.getElementsByClassName("revealLeftLong");
    let fluctuartTitle = document.getElementById("fluctuart-title");
    let pageTransTitle = document.getElementById("mobile-title");
    let pageTransSubTitle = document.getElementById("mobile-subtitle");
    let pageTransyear = document.getElementById("mobile-year");

    if (smallDesktopQuerie.matches){
      let mobileEnterProject = gsap.timeline();
      mobileEnterProject
      .to(pageTransTitle, 0.9, {x: '0', opacity: 1, ease:Power4.easeOut})
      .to(pageTransSubTitle, 0.9, {x: '0', opacity: 1, ease:Power4.easeInOut}, '>-0.7')
      .to(pageTransyear, 1.1, {x: '0', opacity: 0.5, ease:Power4.easeInOut}, '>-0.7')
      .to(pageTransTitle, 1.1, {x: '-35px', opacity: 0, ease:Power4.easeIn}, '>-0.8')
      .to(pageTransSubTitle, 0.9, {x: '-25px', opacity: 0}, '>-0.9')
      .to(pageTransyear, 0.9, {x: '-15px', opacity: 0}, '>-0.7')
      .to(overlayEl2, 0.01, {width:'0'})
      .to(imageRight, 0.01 ,{ x:0})
      .fromTo(overlayEl, 0.6, {width:'100%'}, {width:'0', ease:Power4.easeInOut, onComplete:done});
    } else{
      let enterProject = gsap.timeline();
      enterProject
      .set(fluctuartTitle, { className: "+=opened" }, "-=0.6")
      .staggerFromTo(
        sidebarText,
        0.6,
        { x: "50px", opacity: 0, ease: Power4.easeOut },
        { x: "0", opacity: 1 },
        0.1,
        "-=0.1"
      );
    done();
    }

  }

  out({ from, done }) {
    const homeGallery = document.getElementById("gallery-container");
    const homeSlider = document.getElementById("homegallery");

    if (smallDesktopQuerie.matches) {
      let outTransitionFlutuart = gsap.timeline();
      outTransitionFlutuart
        .set(homeGallery, { className: "+=transition" })
        .fromTo(overlayEl2, 0.6, {width:'0'}, {width:'100%', ease:Power4.easeInOut})
        .to(
          homeSlider,
          0.6,
          { width: "100%", ease: Power4.easeOut, onComplete:done},
          0.1,
          "-=0.65"
        );
    } else {
      let outTransitionFlutuart = gsap.timeline();
      outTransitionFlutuart
        .set(homeGallery, { className: "+=transition" })
        .to(
          homeSlider,
          0.6,
          { width: "77.5%", ease: Power4.easeOut, onComplete: done },
          0.1,
          "-=0.65"
        );
    }
  }
}

export default HomeFluctuart;
