// Import Highway
import Highway from '@dogstudio/highway';

// GSAP Library
import { gsap, Power4 } from "gsap";

// Fade
class Fade extends Highway.Transition {
  in({ from, to, done }) {
    console.log('IN',from,to);
    // Reset Scroll
    window.scrollTo(0, 0);

    // Remove Old View
    from.remove();

    // Animation
    gsap.fromTo(to, 0.5, { opacity: 0 }, {opacity: 1, onComplete: done});
  }

  out({ from, done }) {
    console.log('OUT',from);
    // Animation
    const homeGallery = document.getElementById('gallery-container');
    let outTransition = gsap.timeline();
    outTransition.to(homeGallery, 0.6, {x: '-70px', ease: Power4.easeOut})
    .fromTo(from, 0.5, { opacity: 1 }, {opacity: 0, onComplete: done, ease: Power4.easeOut}, '-=0.6');
  }
}

export default Fade;
  