// Import Highway
import Highway from '@dogstudio/highway';
// Import Home Slider
import homeSlider from './home_slider';

class mainRender extends Highway.Renderer {
  // Hooks/methods
  onEnter() {
    homeSlider();
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    // We listen to the resize event
    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  }
  onLeave() { }
  onEnterCompleted() { }
  onLeaveCompleted() { }
}

// Don`t forget to export your renderer
export default mainRender;