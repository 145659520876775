// Import Highway
import Highway from "@dogstudio/highway";
// Import Home Slider
import btnMobileAnimation from "./btn_mobile_projects";
import defaultSlider from "./teste_Slider_2"

class single_projectRender extends Highway.Renderer {
  // Hooks/methods
  onEnter() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    // We listen to the resize event
    window.addEventListener("resize", () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  }
  onLeave() {
    $('.menu-item').removeClass('dark');
  }
  onEnterCompleted() {
  defaultSlider();
  btnMobileAnimation();

    $("h3").click(function() {
      $(this)
        .next()
        .slideToggle();
      $(this)
        .parent()
        .toggleClass("description-open");
    });
  
    setTimeout(function() {
      $(".details h3").trigger("click");
    }, 900);

    setTimeout(function() {
      $(".slider-pagi__elem-0").trigger("click");
    }, 100);


  }
  onLeaveCompleted() {
    let sideBar = $("#main-wrapper");
    sideBar.removeClass('details-open');
  }
}

// Don`t forget to export your renderer
export default single_projectRender