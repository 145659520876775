// File: HomeFuctuart.js
// Highway
import Highway from "@dogstudio/highway";

// GSAP
import { gsap, Power4 } from "gsap";

const smallDesktopQuerie = window.matchMedia("(max-width: 1280px)");
    
// FluctuartHome
class FluctuartHome extends Highway.Transition {
  in({ from, to, done }) {
    console.log("IN", from, to);
    // Reset Scroll
    window.scrollTo(0, 0);

    // Remove Old View
    from.remove();

    // Animation
    let overlayEl = document.getElementById("overlay-transition");
    let overlayEl2 = document.getElementById("overlay-transition-2");
    let imgItemProj = document.getElementsByClassName('item');

    let inTransitionHome = gsap.timeline();
    inTransitionHome
    .to(overlayEl2, 0.01, {width:'0'})
    .fromTo(overlayEl, 0.6, {width:'100%'}, {width:'0',  ease:Power4.easeInOut})
    .fromTo(imgItemProj, .9, {x:"40px", opacity:0, ease: Power4.easeInOut}, {x:"0", opacity:1, ease: Power4.easeInOut, stagger: {amount: 0.15}}, '>-0.6');

    done();

  }

  out({ from, done }) {

    // Animation
    let sideBar = document.getElementById("right");
    let sidebarText = document.getElementsByClassName("revealLeft");
    let FluctuartTitle = document.getElementById('fluctuart-title');
    let overlayEl2 = document.getElementById("overlay-transition-2");

    let sideBarHide = gsap.timeline();
    sideBarHide
    .set(FluctuartTitle, {className:"+=opened opened2"})
    .to(sidebarText, 0.6, { x: "50px", opacity: 0, stagger: 0.2, ease:Power4.easeInOut})
    .fromTo(overlayEl2, 0.6, {width:'0'}, {width:'100%', ease:Power4.easeInOut, onComplete: done}, '-=0.9')

    if (smallDesktopQuerie.matches){
      gsap.set(sidebarText, {delay:0.3,opacity:0});
    }

  }
}

export default FluctuartHome;
