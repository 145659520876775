function homeSlider() {
  var $slider = $("#homegallery .slider"),
    $slideBGs = $("#homegallery .slide__bg"),
    diff = 0,
    homecurSlide = 0,
    numOfSlides = $("#homegallery .slide").length - 1,
    numOfSlidesBullets = $("#homegallery .slide").length - 1,
    animating = false,
    animTime = 500,
    autoSlideTimeout,
    autoSlideDelay = 6000,
    $pagination = $("#homegallery .slider-pagi");

  function createBullets() {
    for (var i = 0; i < numOfSlidesBullets + 1; i++) {
      var $li = $("<li class='slider-pagi__elem'></li>");
      $li.addClass("slider-pagi__elem-" + i).data("page", i);
      if (!i) $li.addClass("active");
      $pagination.append($li);
    }
  }

  createBullets();

  function manageControls() {
    $(".slider-control").removeClass("inactive");
    if (!homecurSlide) $(".slider-control.left").addClass("inactive");
    if (homecurSlide === numOfSlides)
      $(".slider-control.right").addClass("inactive");
  }

  function autoSlide() {
    autoSlideTimeout = setTimeout(function() {
      homecurSlide++;
      if (homecurSlide > numOfSlides) homecurSlide = 0;
      changeSlides();
    }, autoSlideDelay);
  }

  autoSlide();

  function changeSlides(instant) {
    if (!instant) {
      animating = true;
      manageControls();
      $slider.addClass("animating");
      $slider.css("top");
      $(".slide").removeClass("active");
      $(".slide-" + homecurSlide).addClass("active");
      setTimeout(function() {
        $slider.removeClass("animating");
        animating = false;
      }, animTime);
    }
    window.clearTimeout(autoSlideTimeout);
    $(".slider-pagi__elem").removeClass("active");
    $(".slider-pagi__elem-" + homecurSlide).addClass("active");
    $slider.css("transform", "translate3d(" + -homecurSlide * 100 + "%,0,0)");
    $slideBGs.css("transform", "translate3d(" + homecurSlide * 50 + "%,0,0)");
    diff = 0;
    autoSlide();
  }

  function navigateLeft() {
    if (animating) return;
    if (homecurSlide > 0) homecurSlide--;
    changeSlides();
  }

  function navigateRight() {
    if (animating) return;
    if (homecurSlide < numOfSlides) homecurSlide++;
    changeSlides();
  }

  $(document).on("click", ".slider-control", function() {
    if ($(this).hasClass("left")) {
      navigateLeft();
    } else {
      navigateRight();
    }
  });

  $(document).keydown(function(e) {
    if(e.keyCode == 37) {
      navigateLeft();
    } else if(e.keyCode == 39){
      navigateRight();
    }
  });

  $(document).on("click", ".slider-pagi__elem", function() {
    homecurSlide = $(this).data("page");
    changeSlides();
  });

  function dragonMobile(){
    if($(window).width() < 801 ) {
      $(document).on("mousedown touchstart", ".slider", function(e) {
        if (animating) return;
        window.clearTimeout(autoSlideTimeout);
        var startX = e.pageX || e.originalEvent.touches[0].pageX,
          winW = $(window).width();
        diff = 0;
    
      $(document).on("mousemove touchmove", function(e) {
          var x = e.pageX || e.originalEvent.touches[0].pageX;
          diff = ((startX - x) / winW) * 70;
          if ((!homecurSlide && diff < 0) || (homecurSlide === numOfSlides && diff > 0))
            diff /= 2;
          $slider.css(
            "transform",
            "translate3d(" + (-homecurSlide * 100 - diff) + "%,0,0)"
          );
          $slideBGs.css(
            "transform",
            "translate3d(" + (homecurSlide * 50 + diff / 2) + "%,0,0)"
          );
        });
      });
    
      $(document).on("mouseup touchend", function(e) {
        $(document).off("mousemove touchmove");
        if (animating) return;
        if (!diff) {
          changeSlides(true);
          return;
        }
        if (diff > -8 && diff < 8) {
          changeSlides();
          return;
        }
        if (diff <= -8) {
          navigateLeft();
        }
        if (diff >= 8) {
          navigateRight();
        }
      });
    }
  }

  dragonMobile();

  $(window).resize(dragonMobile);

}

export default homeSlider;
