// Import Highway
import Highway from "@dogstudio/highway";

// GSAP Library
import { gsap, Power4 } from "gsap";

//Variables
let projectText = document.getElementsByClassName("timeline_project");
let projectlocal = document.getElementsByClassName("location");
let projectCross = document.getElementsByTagName("svg");


//Impossibilitar click até funcao estar terminada
    //setTimeout(() => {
    //  $('.Link').css( 'pointer-events', 'all' );
    //}, 2000);

// TimelineTrans
class TimelineTrans extends Highway.Transition {
  in({ from, to, done }) {
    console.log("IN", from, to);
    // Reset Scroll
    window.scrollTo(0, 0);

    // Remove Old View
    from.remove();

    // Animation
    let overlayEl = document.getElementById("overlay-transition");
    let overlayEl2 = document.getElementById("overlay-transition-2");

    //Impossibilitar click até funcao estar terminada
    //setTimeout(() => {
    //  $('.Link').css( 'pointer-events', 'all' );
    //}, 2000);

    let timelineInTl = gsap.timeline();

    timelineInTl
      .to(projectlocal, 0.4, { opacity: "1", x: "0px", stagger: {amount:1, from: "start"} })
      .to(
        projectCross,
        0.2,
        {
          opacity: "1",
          x: "0px",
          rotation: 45,
          stagger: {amount:1, from: "start"}
        },
        "<0.1"
      )
      .to(
        projectText,
        0.4,
        {
          opacity: "1",
          x: "0px",
          stagger: {amount:0.8, from: "start"},
          ease: Power4.easeIn,
          onComplete: done
        },
        "<0.2"
      );
  }

  out({ from, done }) {
    console.log("OUT", from);
    // Animation
    let timelineOutTl = gsap.timeline();

    timelineOutTl
      .to(projectCross, 0.06, {
        opacity: "0",
        x: "-40px",
        rotation: -90,
        stagger: 0.03
      })
      .to(
        projectlocal,
        0.03,
        {
          opacity: "0",
          x: "-10px",
          stagger: 0.03
        },
        "<0.04"
      )
      .to(
        projectText,
        0.25,
        {
          x: "-35px",
          opacity: "0",
          stagger: 0.03,
          onComplete: done
        },
        "<0.04"
      );
  }
}

export default TimelineTrans;
