// Import Highway
import Highway from '@dogstudio/highway';

// GSAP Library
import {gsap, Power4} from 'gsap';

let textAnimate = document.getElementsByClassName('hidetext');

let textRevel = gsap.timeline();
function textRevelAnim(){
      textRevel.staggerFromTo(textAnimate, 0.3, {x:'0', opacity: 0, ease: Power4.easeOut}, {x: '50px', opacity: 1}, 0.1);
    }

textRevelAnim();
// Fade
class Atelier extends Highway.Transition {
  in({ from, to, done }) {
    console.log('IN',from,to);
    // Reset Scroll
    window.scrollTo(0, 0);

    // Remove Old View
    from.remove();

    // Animation
    textRevelAnim();
    done();
  }

  out({ from, done }) {
    console.log('OUT',from);
    // Animation
    let outTransition = gsap.timeline();
    outTransition.fromTo(from, 0.6, { opacity: 1 }, {opacity: 0, onComplete: done});
  }
}

export default Atelier;
  