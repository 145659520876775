// File: HomeFuctuart.js
// Highway
import Highway from "@dogstudio/highway";

// GSAP
import { gsap, Power4 } from "gsap";

const smallDesktopQuerie = window.matchMedia("(max-width: 1280px)");
    
// BetweenProjects
class BetweenProjects extends Highway.Transition {
  in({ from, to, done }) {
    console.log("IN", from, to);
    // Reset Scroll
    window.scrollTo(0, 0);

    // Remove Old View
    from.remove();

    // Animation
    let overlayEl = document.getElementById("overlay-transition");
    let overlayEl2 = document.getElementById("overlay-transition-2");
    let FluctuartTitle = document.getElementById('fluctuart-title');
    let sidebarText = document.getElementsByClassName("revealLeft");
    let sidebarRight = document.getElementsByClassName("right");
    let imageRight = document.getElementsByClassName("revealLeftLong");

    // Animation Mobile
    const smallDesktopQuerie = window.matchMedia("(max-width: 1280px)");
    let fluctuartTitle = document.getElementById("fluctuart-title");
    let pageTransTitle = document.getElementById("mobile-title");
    let pageTransSubTitle = document.getElementById("mobile-subtitle");
    let pageTransyear = document.getElementById("mobile-year");
    if (smallDesktopQuerie.matches){
      let mobileEnterProject = gsap.timeline();
      mobileEnterProject
      .fromTo(overlayEl2, 0.6, {width:'0'}, {width:'100%', ease:Power4.easeInOut})
      .to(pageTransTitle, 0.9, {x: '0', opacity: 1, ease:Power4.easeOut})
      .to(pageTransSubTitle, 0.9, {x: '0', opacity: 1, ease:Power4.easeInOut}, '>-0.7')
      .to(pageTransyear, 1.1, {x: '0', opacity: 0.5, ease:Power4.easeInOut}, '>-0.7')
      .to(pageTransTitle, 1.1, {x: '-35px', opacity: 0, ease:Power4.easeIn}, '>-0.8')
      .to(pageTransSubTitle, 0.9, {x: '-25px', opacity: 0}, '>-0.9')
      .to(pageTransyear, 0.9, {x: '-15px', opacity: 0}, '>-0.7')
      .to(overlayEl2, 0.01, {width:'0'})
      .to(imageRight, 0.01 ,{ x:0})
      .fromTo(overlayEl, 0.6, {width:'100%'}, {width:'0', ease:Power4.easeInOut, onComplete:done});
      done();
    } else {
      // Animation desktop
      let inTransitionHome = gsap.timeline();
      inTransitionHome
      .to(overlayEl2, 0.01, {width:'0'})
      .fromTo(overlayEl, 0.5, {width:'100%'}, {width:'0', ease:Power4.easeInOut})
      .staggerFromTo(sidebarText, 0.6, {x:'50px', opacity: 0, ease: Power4.easeOut}, {x: '0', opacity: 1}, 0.1, '-=0.1')
      .fromTo(imageRight, 0.5, {x:'30px', opacity:0, ease: Power4.easeIn,}, {x: '0', opacity:1, onComplete: done}, '-=1.3')
      .set(FluctuartTitle, { css:{ className:"-=opened" }}, "-=0.9" );
    }

  }
  

  out({ from, done }) {

    // Animation
    let fluctuartGallery = document.getElementById("ProjectGallery");
    let sidebarText = document.getElementsByClassName("revealLeft");
    let sliderFContainer = document.getElementsByClassName("single-Project-container");
    let FluctuartTitle = document.getElementById('fluctuart-title');
    let overlayEl2 = document.getElementById("overlay-transition-2");

    if($('#fluctuart-title').hasClass('opened')){
      $('#fluctuart-title').addClass('opened2');
    };    

    let sideBarHide = gsap.timeline();
    sideBarHide
    .to(sidebarText, 0.6, { x: "50px", opacity: 0, stagger: 0.2, ease:Power4.easeInOut})
    .fromTo(overlayEl2, 0.6, {width:'0'}, {width:'100%', ease:Power4.easeInOut, onComplete:done}, '-=0.9')

    if (smallDesktopQuerie.matches){
      gsap.set(sidebarText, {delay:0.3,opacity:0});
    }

  }
}

export default BetweenProjects;
