import { gsap, Power4 } from "gsap";

function btnMobileAnimation(){
    //  FLUCTUART DETAILS ANIMATION
    var enterText = document.getElementsByClassName("revealLeft");
    var sideBar = $("#main-wrapper");

    $("#more_details_btn").click(function () {
      if (sideBar.hasClass("details-open")) {
        sideBar.removeClass("details-open");
        var plusDetailsOut = gsap.timeline();
        plusDetailsOut.to(
          enterText,
          0.3,
          {
            x:'50px', opacity:0, stagger: {
            each: 0.05,
            from: "end"
          }
        })
        .to(
          enterText,
          0.1,
          {
            x:'0px', opacity:1
        })
      } else {
        sideBar.addClass("details-open");
        var plusDetails = gsap.timeline();
        plusDetails.staggerFromTo(
          enterText,
          0.6,
          { x: "50px", opacity: 0, ease: Power4.easeOut },
          { x: "0", opacity: 1 },
          0.2
        );
        gsap.set("#fluctuart-title", { css:{ className:"-=opened opened-menu revealLeft" }}, "-=0.9" );
        
      }
    });
}

export default btnMobileAnimation;