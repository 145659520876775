// Import Highway
import Highway from '@dogstudio/highway';

// GSAP Library
import {gsap, Power1} from 'gsap';

//CUSTOM ANIM
function imageHover() {
    $('.item').hover(
      function(){ 
        $(this).removeClass('nothover');
        $(this).addClass('hover');
        $('.item').not($(this)).addClass('nothover')
      },
      function(){
        $(this).removeClass('hover');
        $('.item').removeClass('nothover')
      },
    );
}

imageHover();

function menuHover(){
    $('.header-container').hover(
        function(){ 
           $('.item').addClass('nothover');
        },
        function(){
           $('.item').removeClass('nothover');
        },
      );
}

menuHover();

const imgItem = document.getElementsByClassName('item');
const notSelectedImg = document.getElementsByClassName('nothover');
const selectedImg = document.getElementsByClassName('hover');
const selectedTitle = document.getElementsByClassName('title');

function projectTimelineIn(){
let projectTimelineIn = gsap.timeline();
projectTimelineIn
.fromTo(imgItem, .6, {x:"40px", opacity:0, ease: Power1.easeInOut}, {x:"0", opacity:1, ease: Power1.easeInOut, stagger: {amount: 0.6}});
};

projectTimelineIn();

function openInSlide(){
  $('.item').click(function() {
    var numSlide = $(this).attr('data-index');
    $('body').attr('data-index', numSlide);
  });
}

openInSlide();

// Fade
class Projects extends Highway.Transition {
  in({ from, to, done }) {
    console.log('IN',from,to);
    // Reset Scroll
    window.scrollTo(0, 0);

    // Remove Old View
    from.remove();

    // Animation
    openInSlide();
    imageHover();
    projectTimelineIn();
    done();
  }

  out({ from, done }) {
    console.log('OUT',from);
    // Animation

    //$('.item').addClass('transition');

    $('.item').removeAttr( "data-aos" );

    let projectTimelineOut = gsap.timeline();
    projectTimelineOut
    .to(notSelectedImg, .3, {x:"-20px", opacity:0, ease: Power1.easeInOut, stagger: {amount: 0.15, from: 'random'}})
    .to(selectedImg, 0.3, {x:"-20px", opacity:0, ease: Power1.easeInOut, onComplete: done}, '-=0.4')
  }
}

export default Projects;
  