// Import Highway
import Highway from "@dogstudio/highway";

// GSAP Library
import { gsap, Power4 } from "gsap";

class timelineRender extends Highway.Renderer {
  // Hooks/methods
  onEnter() {
    $(".timeline-link").click(function (event) {
      $(this).addClass("clicked");
      $(".timeline-img").fadeTo(1, 0);
    });
  }
  onLeave() {}
  onEnterCompleted() {
    let projectText = document.getElementsByClassName("timeline_project");
    let projectlocal = document.getElementsByClassName("location");
    let projectCross = document.getElementsByTagName("svg");
    let timelineInTl = gsap.timeline();

    timelineInTl
      .to(projectlocal, 0.4, { opacity: "1", x: "0px", stagger: 0.05 })
      .to(
        projectCross,
        0.4,
        {
          opacity: "1",
          x: "0px",
          rotation: 45,
          ease: Power4.easeIn,
          stagger: 0.05,
        },
        "<0.01"
      )
      .to(
        projectText,
        0.4,
        {
          opacity: "1",
          x: "0px",
          stagger: 0.05,
          ease: Power4.easeIn
        },
        "<0.3"
      );
  }
  onLeaveCompleted() {}
}

// Don`t forget to export your renderer
export default timelineRender;
