// Import Highway
import Highway from "@dogstudio/highway";

// GSAP Library
import { gsap, Power4 } from "gsap";

//Variables
let projectText = document.getElementsByClassName("timeline_project");
let projectTitle = document.getElementsByClassName("title");
let projectlocal = document.getElementsByClassName("location");
let projectCross = document.getElementsByTagName("svg");

const smallDesktopQuerie = window.matchMedia("(max-width: 1280px)");

// TimelineProjectTrans
class TimelineProjectTrans extends Highway.Transition {
  in({ from, to, done }) {
    console.log("IN", from, to);
    // Reset Scroll
    window.scrollTo(0, 0);

    // Remove Old View
    from.remove();

    // Animation
    let sidebarText = document.getElementsByClassName("revealLeft");
    let imageRight = document.getElementsByClassName("revealLeftLong");
    let overlayEl = document.getElementById("overlay-transition");
    let overlayEl2 = document.getElementById("overlay-transition-2");


    if (smallDesktopQuerie.matches){
      gsap.to(overlayEl2, 0.01, {width: "0"})
      gsap.fromTo(overlayEl, 0.3, {width: "100%"}, {width: "0", ease: Power4.easeOut})

    }
    

    let timelinePInTl = gsap.timeline();
    timelinePInTl
      .fromTo(
        imageRight,
        0.4,
        { x: "30px", opacity: 0, ease: Power4.easeIn },
        { x: "0", opacity: 1 }
        )
      .staggerFromTo(
        sidebarText,
        0.6,
        { x: "50px", opacity: 0, ease: Power4.easeOut },
        { x: "0", opacity: 1 },
        0.1,
        "-=0.1"
      )
      .fromTo(
        imageRight,
        0.5,
        { x: "30px", opacity: 0, ease: Power4.easeIn },
        { x: "0", opacity: 1 },
        "-=1.3"
      )
      .set(
        "#fluctuart-title",
        { css: { className: "-=opened" }, onComplete: done },
        "-=0.9"
      );
  }

  out({ from, done }) {
    console.log("OUT", from);
    // Animation
    let overlayEl = document.getElementById("overlay-transition");
    let overlayEl2 = document.getElementById("overlay-transition-2");
    let projectImg = document.getElementsByClassName("overlay_img_container");


    if (smallDesktopQuerie.matches){
      let timelinePOutTl = gsap.timeline();
      timelinePOutTl
      .to(projectCross, 0.06, {
        opacity: "0",
        x: "-40px",
        rotation: -90,
        stagger: {
          each: -0.03,
          from: "center"
        }
      })
      .to(
        projectlocal,
        0.03,
        {
          opacity: "0",
          x: "-10px",
          stagger: {
            each: -0.03,
            from: "center"
          }
        },
        "<0.04"
      )
      .to(
        projectText,
        0.25,
        {
          x: "-35px",
          opacity: "0",
          stagger: {
            each: -0.04,
            from: "center"
          }
        },
        "<0.04"
      )
      .fromTo(
        overlayEl2,
        0.8,
        { width: "0" },
        { width: "100%", ease: Power4.easeInOut, onComplete: done },
        "-=0.03"
      );
      
    } else{
      let timelinePOutTl = gsap.timeline();
      timelinePOutTl
      .to(projectCross, 0.06, {
        opacity: "0",
        x: "-40px",
        rotation: -90,
        stagger: {
          each: -0.03,
          from: "center"
        }
      })
      .to(
        projectlocal,
        0.03,
        {
          opacity: "0",
          x: "-10px",
          stagger: {
            each: -0.03,
            from: "center"
          }
        },
        "<0.04"
      )
      .to(
        projectText,
        0.25,
        {
          x: "-35px",
          opacity: "0",
          stagger: {
            each: -0.04,
            from: "center"
          }
        },
        "<0.04"
      )
      .to(
        projectImg,
        0.4,
        { opacity: "0", ease: Power4.easeInOut, onComplete: done},
        "=0.1"
      );
    }

  }
}

export default TimelineProjectTrans;
